<template>
  <div
    class="backTop"
    ref="backTop"
    v-show="backTopIsStatus"
    @click="toGoTop"
  >
    <van-image
      width="50"
      :src="backTop"
    />
  </div>
</template>

<script>
import { BACKTOP } from '@/utils/imageManage'
import { Image as VanImage } from 'vant'

export default {
  name: 'BackTop',
  data () {
    return {
      backTop: BACKTOP,
      isWeChatHideIcon: true,
      backTopIsStatus: false
    }
  },
  methods: {
    // 返回顶部图标
    toTopScrollFn () {
      document.onscroll = () => {
        document.documentElement.scrollTop || document.body.scrollTop > 200 ? this.backTopIsStatus = true : this.backTopIsStatus = false
      }
    },
    toGoTop () {
      let timer = setInterval(() => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-scrollTop / 10)
        if (scrollTop === 0) clearInterval(timer)
        document.documentElement.scrollTop = document.body.scrollTop = scrollTop + ispeed
      }, 0)
    }
  },
  mounted () {
    this.toTopScrollFn()
  },
  components: {
    VanImage
  }
}
</script>

<style lang="less">
.backTop {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 5px;
  bottom: 100px;
  z-index: 100;
  background-color: #fff;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
