<template>
  <div class="container">
    <div class="container-con">
      <!-- Header S -->
      <Head
        :backIsShow="true"
      />
      <!-- Header E -->
      <!-- content - product info S -->
      <section class="m-dxby-con-info-wrap">
        <h2>商品基本信息</h2>
        <section class="m-dxby-con-p-info">
          <div class="m-dxby-con-p-info-img" @click="clickImagePreview(prdDetailsData.mainImg)">
            <img :src="prdDetailsData.mainImg" :alt="prdDetailsData.name">
          </div>
          <div class="m-dxby-con-p-info-text">
            <i
              v-for="(item, key) in supAttrList"
              :key="key"
              :class="item.haveClassName ? 'm-dxby-info-parameter-h3' : ''"
              :style="{marginTop: item.marginTop ? item.marginTop + 'px' : '' }"
            >
            <span :style="{paddingRight: item.haveClassName ? '3px' : ''}">{{item.attrName}}</span><span v-show="item.haveClassName ? false : true">:</span> {{item.attrValue}}
            </i>
          </div>
        </section>
      </section>
      <!-- content - product info E -->
      <!-- prodcution details infos S -->
      <section class="m-dxby-pDetails-info-tab">
        <ul>
          <li
            v-for="(item, key) in tabData"
            :key="key"
            @click="tabFn(key)"
            :class="index === key ? 'activeShow' : ''"
          >
            <i>{{item}}</i>
            <span></span>
          </li>
        </ul>
      </section>
      <div class="m-dxby-pDetails-info-tab-content">
        <!-- 色卡 S -->
        <div v-if="index === 0" class="m-dxby-color-card">
          <ul>
            <li
              v-for="(cardItem, key) in spuList"
              :key="key"
              :style="{marginBottom: cardItem.showName === true ? 0 : ''}"
              @click.stop="imagePreviewFn(cardItem, key)"
            >
              <div class="m-dxby-color-card-img-wrap">
                <b class="m-dxby-color-card-shadow"></b>
                <van-image
                  width="100%"
                  fit="cover"
                  :src="cardItem.image"
                  :alt="cardItem.name"
                  v-if="cardItem.image && cardItem.image !== ''"
                />
                <div v-else class="m-dxby-color-card-noImage">待更新</div>
                <span></span>
                <i>{{cardItem.sn}}</i>
              </div>
              <span v-if="cardItem.showName" class="m-dxby-color-card-name">{{cardItem.name}}</span>
            </li>
          </ul>
        </div>
        <!-- 色卡 E -->
        <!-- 详情页 S -->
        <div
          v-else-if="index === 1"
          class="m-dxby-pDetails-info-des"
          v-html="prdDetailsData.detail"
        >
        </div>
        <!-- 详情页  E -->
        <!-- 温馨提示 S -->
        <div v-else v-html="productionTips" class="m-dxby-pDetails-info-tips"></div>
        <!-- 温馨提示 E -->
      </div>
      <!-- prodcution details E -->
      <!-- 新品推荐 S -->
      <section class="m-dxby-newP-wrap">
        <div class="m-dxby-newP-top">
          <span>新品推荐</span>
          <router-link to="/product">更多 >></router-link>
        </div>
        <div class="m-dxby-newP-list">
          <ul>
            <li
              v-for="(newPrdItem, key) in newPrdData"
              :key="key"
              @click="toNewProductDetails(newPrdItem)"
            >
              <a href="">
                <van-image
                  width="100%"
                  height="100%"
                  fit="cover"
                  :src="newPrdItem.mainImg"
                />
                <div class="m-dxby-newP-list-info">
                  <span>{{newPrdItem.sn}}</span>
                  <span>{{newPrdItem.name}}</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>
      <!-- 新品推荐 E -->
    </div>
    <!-- 色卡列表预览 S -->
    <div
      v-show="imagePreviewShow"
      @click.stop="imagePreviewShow = false"
      class="m-dxby-overlay-wrap"
    >
      <div class="m-dxby-wrapper" @click.stop>
        <div class="m-dxby-imagePreview-slideImg-item">
          <div class="m-dxby-imagePreview-title">
            <p>{{imagePreviewItemData.sn}}号-{{imagePreviewItemData.name}}</p>
            <span @click="() => imagePreviewShow = false">
              <img :src="closeIcon" alt="">
            </span>
          </div>
          <div class="m-dxby-imagePreview-img">
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              :src="imagePreviewItemData.image"
            />
          </div>
        </div>
        <div class="m-dxby-imagePreview-slideImg-total">第 <i>{{imagePreviewIndex + 1}}</i> 张</div>
      </div>
    </div>
    <!-- 色卡列表预览 E -->
    <BackTop/>
    <Foot/>
  </div>
</template>

<script>
import axios from 'axios'
import Head from '@/components/Head'
import Foot from '@/components/Foot'
import metaLable from '@/utils/metaLable'
import BackTop from '@/components/BackTop'
import { productionTips, tabData } from '@/mock'
import { OtherCommonFn } from '@/assets/js/common'
import { Image as VanImage } from 'vant'
import { CLOSE_ICON } from '@/utils/imageManage'
import { hostApi } from '@/utils/requestApi'
// import { swiper as vSwiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'Product_details',
  data () {
    return {
      prdDetailsData: {},
      supAttrList: {},
      spuList: [],
      newPrdData: [],
      closeIcon: CLOSE_ICON,
      index: 0,
      productionTips,
      tabData,
      imagePreviewItemData: {},
      imagePreviewIndex: 0,
      imagePreviewShow: false,
      swiperOption: {
        loop: false,
        autoplay: false
      }
    }
  },
  metaInfo: {
    title: '大新布业 - 产品详情',
    meta: metaLable
  },
  mounted () {
    this.getSku()
    this.getPrdDetailsData()
    this.getNewPrdData()
  },
  methods: {
    // 获取到详情页数据
    async getPrdDetailsData () {
      const { id } = this.$route.query
      const res = await axios.post(`${hostApi}/goods/spu/detail`, { id })
      const { supAttrList, sn, name } = res.data
      this.prdDetailsData = res.data
      supAttrList.splice(0, 0, { attrName: `${sn}`, attrValue: `${name}`, haveClassName: true })
      supAttrList.splice(3, 0, { attrName: '详细参数', haveClassName: true, marginTop: 10 })
      this.supAttrList = supAttrList
    },
    // 选项卡
    tabFn (key) {
      this.index = key
    },
    // 详情页大图预览
    clickImagePreview (mainImg) {
      new OtherCommonFn().isImagePreviewFn([mainImg], true, 0)
    },
    // 色卡列表图片预览
    imagePreviewFn (item, index) {
      this.imagePreviewShow = true
      this.imagePreviewItemData = item
      this.imagePreviewIndex = index
    },
    // 色卡
    async getSku () {
      const { id } = this.$route.query
      const res = await axios.post(`${hostApi}/goods/sku/list/spu`, { id })
      this.spuList = res.data
    },
    // 新品推荐
    async getNewPrdData () {
      const _data = {
        id: '1401403802059325441',
        pageNum: 1,
        pageSize: 12
      }
      const res = await axios.post(`${hostApi}/goods/spu/list/cls`, _data)
      const { list } = res.data
      this.newPrdData = list
    },
    toNewProductDetails (item) {
      this.$router.push({
        path: 'productDetails',
        query: {
          id: item.id
        }
      })
    }
  },
  components: {
    Head,
    Foot,
    BackTop,
    VanImage
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
// 商品信息
.m-dxby-con-info-wrap {
  margin: 0 10px;
  h2 {
    display: block;
    .fsize(18px);
    color: #333;
    font-family: '微软雅黑';
    margin: 10px 0 32px;
  }
  .m-dxby-con-p-info {
    display: flex;
    .m-dxby-con-p-info-img {
      .w(50%);
      img {
        .wh(100%, 100%);
      }
    }
    .m-dxby-con-p-info-text {
      // 最大为260px
      .w(49%);
      box-sizing: border-box;
      padding-left: 10px;
      h3 {
        display: block;
        .fsize(16px);
        color: #333;
        font-family: '微软雅黑';
        margin-bottom: 10px;
        .otx(2);
      }
      .m-dxby-info-parameter-h3 {
        display: block;
        .fsize(16px);
        color: #333;
        font-family: '微软雅黑';
        margin-bottom: 10px;
        .otx(2);
      }
      .m-dxby-info-parameter {
        margin-top: 10px;
      }
      i {
        display: block;
        color: #666;
        .fsize(14px);
        margin-bottom: 2px;
      }
    }
  }
}
// 商品详情 - 选项卡
.m-dxby-pDetails-info-tab {
  margin: 80px 10px 20px;
  border-bottom: 1px solid #bdbdbd;
  ul {
    display: flex;
    li {
      .w(86px);
      .lht(35px);
      .fsize(14px);
      color: #333;
      text-align: center;
      font-family: '微软雅黑';
      border-top: 1px solid #bdbdbd;
      border-right: 1px solid #bdbdbd;
      position: relative;
    }
    li:first-child {
      border-left: 1px solid #bdbdbd;
    }
    li.activeShow {
      i {
        font-weight: bold;
      }
      span {
        .wh(100%, 1px);
        .bg(#fff);
        position: absolute;
        bottom: -1px;
        left: 0;
        font-weight: bold;
      }
    }
  }
}
// 选项卡内容
.m-dxby-pDetails-info-tab-content {
  margin: 0 8px 60px;
  // 详情
  .m-dxby-pDetails-info-tips {
    .fsize(14px);
    color: #333;
    .lht(30px);
  }
  // 温馨提示
  /deep/.m-dxby-pDetails-info-des {
    text-align: center;
    img {
      width: 100%;
    }
  }
  // 色卡
  .m-dxby-color-card {
    ul {
      .w(104%);
      display: flex;
      flex-wrap: wrap;
      li {
        .w(29.8%);
        margin-bottom: 20px;
        position: relative;
        margin-right: 12px;
        box-sizing: border-box;
        .m-dxby-color-card-img-wrap {
          .wh(100%, 90px);
          .rds(8px);
          border-bottom-right-radius: 0;
          margin: 0 auto;
          position: relative;
          overflow: hidden;
          .m-dxby-color-card-shadow {
            .wh(100%, 100%);
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 6;
            box-shadow: 2px 0 7px #333 inset;
          }
          .m-dxby-color-card-noImage {
            .fsize(12px);
            .lht(90px);
            text-align: center;
            color: #c72b40;
          }
          span {
            .wh(0, 0);
            position: absolute;
            bottom: 0;
            right: 0;
            border-bottom: 30px solid #fff;
            border-left: 45px solid transparent;
            z-index: 8
          }
          i {
            .fsize(12px);
            text-align: center;
            position: absolute;
            bottom: 0px;
            right: 0px;
            z-index: 10;
          }
        }
        .m-dxby-color-card-name {
          display: block;
          .fsize(12px);
          color: #333;
          text-align: center;
          margin: 10px 0 20px;
          .otx(1);
        }
      }
    }
  }
}
// 新品推荐
.m-dxby-newP-wrap {
  margin-bottom: 50px;
  .m-dxby-newP-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px;
    padding: 20px 0;
    border-top: 1px solid #bdbdbd;
    span {
      .fsize(15px);
      color: #333;
      font-weight: bold;
    }
    a {
      .fsize(12px);
      color: #2f5293;
      font-weight: bold;
    }
  }
  .m-dxby-newP-list {
    margin: 0 8px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        .w(33.3%);
        margin-bottom: 10px;
        box-sizing: border-box;
        a {
          display: block;
          .wh(92%, 80px);
          .rds(8px);
          overflow: hidden;
          margin: 0 auto;
          position: relative;
          .m-dxby-newP-list-info {
            .wh(100%, 100%);
            position: absolute;
            top: 0;
            left: 0;
            .bg(rgba(0,0,0,.3));
            .fsize(12px);
            span {
              color: rgba(255,255,255,.7);
              display: block;
              text-align: center;
              margin: 0 auto;
            }
            span:first-child {
              font-weight: bold;
              padding-bottom: 5px;
              margin-top: 13px;
              .otx(1);
            }
            span:nth-child(2) {
              max-width: 90%;
              .otx(2);
              .lht(18px);
              padding-top: 5px;
              border-top: 1px solid rgba(255,255,255,.7)
            }
          }
        }
      }
    }
  }
}
// 色卡图片列表预览
.m-dxby-overlay-wrap {
  .wh(100%, 100%);
  position: fixed;
  top: 0;
  left: 0;
  .bg(rgba(0,0,0,.8));
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .m-dxby-wrapper {
    .w(100%);
    .bg(#fff);
  }
  .m-dxby-imagePreview-slideImg-item {
    .wh(100%, 100%);
    .m-dxby-imagePreview-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        .h(50px);
        .lht(50px);
        color: #333;
        flex: 1;
        text-align: center;
      }
      img {
        .w(20px);
        margin-right: 10px;
      }
    }
    .m-dxby-imagePreview-img {
      .bg(#fff);
      flex: 1;
      overflow: hidden;
      box-sizing: border-box;
    }
  }
  .m-dxby-imagePreview-slideImg-total {
    text-align: center;
    padding: 10px 0;
    .fsize(14px);
    color: #333;
    i {
      font-weight: bold;
    }
  }
}
// 最小设备
@media screen and (width:320px){
  // 新品推荐
  .m-dxby-newP-wrap {
    .m-dxby-newP-list {
      ul {
        li {
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
